import React from "react";
import Fade from "react-reveal/Fade";
import { Trans } from 'react-i18next';

const ServiceContent = [
  {
    no: "01",
    service: "RESPONSIVE LAYOUT",
    title: <Trans>Service Title 1</Trans>,
    desc: <Trans>Service Description 1</Trans>,
  },
  {
    no: "02",
    service: "THEME CUSTOMIZER",
    title: <Trans>Service Title 2</Trans>,
    desc: <Trans>Service Description 2</Trans>,
  },
  {
    no: "03",
    service: "MOBILE COMPATIBILITY",
    title: <Trans>Service Title 3</Trans>,
    desc: <Trans>Service Description 3</Trans>,
  },
  {
    no: "04",
    service: "AWESOME SUPPORT",
    title: <Trans>Service Title 4</Trans>,
    desc: <Trans>Service Description 4</Trans>,
  },
  {
    no: "05",
    service: "INFINITE GALLERY",
    title: <Trans>Service Title 5</Trans>,
    desc: <Trans>Service Description 5</Trans>,
  },
  {
    no: "06",
    service: "EASY TO USE",
    title: <Trans>Service Title 6</Trans>,
    desc: <Trans>Service Description 6</Trans>,
  },
];

const ServiceTwo = () => {
  return (
    <>
      <div className="row gy-4">
        {ServiceContent.map((val, i) => (
          <div className="col-sm-6 col-lg-4" key={i}>
            <div className="feature-box-02">
              <Fade bottom>
                <h6>
                  <span>{val.no}.</span> {val.title}
                </h6>
                {/* <h5>{val.title}</h5> */}
                <p>{val.desc}</p>
              </Fade>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ServiceTwo;
