import React from "react";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import { Trans } from 'react-i18next';

const ServiceContent = [
  {
    icon: "ti-ruler-pencil",
    title: <Trans>Service Title 1</Trans>,
    descriptions: <Trans>Service Description 1</Trans>,
  },
  {
    icon: "ti-image",
    title: <Trans>Service Title 2</Trans>,
    descriptions: <Trans>Service Description 2</Trans>,
  },
  {
    icon: "ti-layout",
    title: <Trans>Service Title 3</Trans>,
    descriptions: <Trans>Service Description 3</Trans>,
  },
  {
    icon: "ti-brush-alt",
    title: <Trans>Service Title 4</Trans>,
    descriptions: <Trans>Service Description 4</Trans>,
  },
  {
    icon: "ti-camera",
    title: <Trans>Service Title 5</Trans>,
    descriptions: <Trans>Service Description 5</Trans>,
  },
  {
    icon: "ti-world",
    title: <Trans>Service Title 6</Trans>,
    descriptions: <Trans>Service Description 6</Trans>,
  },
];

export default function Service() {
  return (
    <>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div className="col-md-6 col-lg-4 my-3" key={i}>
            <Zoom>
              <div className="feature-box-01">
                <Fade bottom>
                  <div>
                    <div className="icon">
                      <i className={val.icon}></i>
                    </div>
                    <div className="feature-content">
                      <h5>{val.title}</h5>
                      <p>{val.descriptions}</p>
                    </div>
                  </div>
                </Fade>
              </div>
              {/* End .feature-box-01 */}
            </Zoom>
          </div>
        ))}
        {/* End .col */}
      </div>
    </>
  );
}
