import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from "./reportWebVitals";
import "./assets/scss/main.scss";

// import i18n (needs to be bundled ;))
import './i18n';

ReactDOM.render(  
<BrowserRouter basename="/home">
    <App />
</BrowserRouter>
, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
