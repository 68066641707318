import React from "react";
import Routes from "./router/Routes";
import ScrollToTop from "./components/ScrollToTop";
import { useTranslation,  withTranslation } from "react-i18next";

const App = () => {
  const { t, i18n } = useTranslation();
  i18n.changeLanguage('tr');
  return (
    <>
      <ScrollToTop />
      <Routes />
      {/* End demo-toggler */}
    </>
  );
};

export default withTranslation()(App);
