import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Fade from "react-reveal/Fade";
import { Trans } from 'react-i18next';

export default function SimpleSlider() {
  var settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    margin: 30,
    responsive: [
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const TestimonilContent = [
    {
      imageName: "team-1",
      desc: <Trans>Review Description 1</Trans>,
      reviewerName: <Trans>Reviewer Name 1</Trans>,
      rating: <Trans>Review Rating 1</Trans>,
    },
    {
      imageName: "team-2",
      desc: <Trans>Review Description 2</Trans>,
      reviewerName: <Trans>Reviewer Name 2</Trans>,
      rating: <Trans>Review Rating 2</Trans>,
    },
    {
      imageName: "team-4",
      desc: <Trans>Review Description 3</Trans>,
      reviewerName: <Trans>Reviewer Name 3</Trans>,
      rating: <Trans>Review Rating 3</Trans>,
    },
  ];

  return (
    <>
      <Slider {...settings}>
        {TestimonilContent.map((val, i) => (
          <div className="testmonial-box media" key={i}>
            <Fade bottom>
              <div className="avatar">
                <img
                  src={`img/testimonial/${val.imageName}.jpg`}
                  alt="review comments"
                ></img>
              </div>
            </Fade>
            {/* End avatar */}
            <Fade bottom>
              <div className="testmonial-text media-body">
                <p>{val.desc}</p>
                <div className="testmonial-info">
                  <h6>{val.reviewerName}</h6>
                  <div className="rating-value">
                    <i className="ti-star"></i>
                    <span>{val.rating}</span>
                  </div>
                </div>
              </div>
            </Fade>
            {/* End testimonial-text */}
          </div>
        ))}
      </Slider>
    </>
  );
}
