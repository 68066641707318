import React, { useState } from "react";
import Modal from "react-modal";
import Contact from "../../components/contact/Contact";
import Fade from "react-reveal/Fade";
import { Trans } from 'react-i18next';

Modal.setAppElement("#root");

const Blog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  function toggleModalTwo() {
    setIsOpen2(!isOpen2);
  }
  function toggleModalThree() {
    setIsOpen3(!isOpen3);
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-4 col-md-6 my-3">
          <div className="blog-post" onClick={toggleModalOne}>
            <div className="blog-img">
              <div className="data">
                <span>14</span>
                <small>Jan</small>
              </div>
              <Fade bottom>
                <img src="img/blog/blog-1.jpg" alt="blog post"></img>
              </Fade>
            </div>
            {/* End blog-img */}

            <div className="blog-info">
              <Fade bottom>
                <h6><Trans>Blog Header 1</Trans></h6>
                <p>
                  <Trans>Blog Description Short 1</Trans>
                </p>
                <div className="btn-bar"><Trans>Read More</Trans></div>
              </Fade>
            </div>
            {/* End blog-info */}
          </div>
          {/* End blog-post */}
        </div>
        {/* End .col for blog-1 */}

        <div className="col-lg-4 col-md-6 my-3">
          <div className="blog-post" onClick={toggleModalTwo}>
            <div className="blog-img">
              <div className="data">
                <span>07</span>
                <small>Aug</small>
              </div>
              <Fade bottom>
                <img src="img/blog/blog-2.jpg" alt="blog post"></img>
              </Fade>
            </div>
            {/* End blog-img */}

            <div className="blog-info">
              <Fade bottom>
                <h6><Trans>Blog Header 2</Trans></h6>
                <p>
                  <Trans>Blog Description Short 2</Trans>
                </p>
                <div className="btn-bar"><Trans>Read More</Trans></div>{" "}
              </Fade>
            </div>
            {/* End blog-info */}
          </div>
          {/* End blog-post */}
        </div>
        {/* End .col for blog-1 */}

        <div className="col-lg-4 col-md-6 my-3">
          <div className="blog-post" onClick={toggleModalThree}>
            <div className="blog-img">
              <div className="data">
                <span>22</span>
                <small>Apr</small>
              </div>
              <Fade bottom>
                <img src="img/blog/blog-3.jpg" alt="blog post"></img>
              </Fade>
            </div>
            {/* End blog-img */}

            <div className="blog-info">
              <Fade bottom>
                <h6><Trans>Blog Header 3</Trans></h6>
                <p>
                  <Trans>Blog Description Short 3</Trans>
                </p>
                <div className="btn-bar"><Trans>Read More</Trans></div>{" "}
              </Fade>
            </div>
            {/* End blog-info */}
          </div>
          {/* End blog-post */}
        </div>
        {/* End .col for blog-1 */}
      </div>
      {/* End .row */}

      {/* Start Modal for Blog-1 */}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalOne}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/blog/blog-1.jpg" alt="blog post"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>
                      <Trans>Blog Header 1</Trans>
                    </h2>
                    <div className="media">
                      <div className="avatar">
                        <img src="img/testimonial/team-1.jpg" alt="thumbnail" />
                      </div>
                      <div className="media-body">
                        <label><Trans>Blog Writer 1</Trans></label>
                        <span><Trans>Blog Date 1</Trans></span>
                      </div>
                    </div>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                    <Trans>Blog Description Long 1</Trans>
                  </div>
                  {/* End article content */}
                </article>
                {/* End Article */}

                <div className="contact-form article-comment">
                  <h4><Trans>Leave a Reply</Trans></h4>
                  <Contact />
                </div>
                {/* End .contact Form */}
              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for Blog-1 */}

      {/* Start Modal for Blog-2 */}
      <Modal
        isOpen={isOpen2}
        onRequestClose={toggleModalTwo}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalTwo}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/blog/blog-2.jpg" alt="blog post"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>
                      <Trans>Blog Header 2</Trans>
                    </h2>
                    <div className="media">
                      <div className="avatar">
                        <img src="img/testimonial/team-2.jpg" alt="thumbnail" />
                      </div>
                      <div className="media-body">
                        <label><Trans>Blog Writer 2</Trans></label>
                        <span><Trans>Blog Date 2</Trans></span>
                      </div>
                    </div>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                    <Trans>Blog Description Long 2</Trans>
                  </div>
                  {/* End article content */}
                </article>
                {/* End Article */}

                <div className="contact-form article-comment">
                  <h4><Trans>Leave a Reply</Trans></h4>
                  <Contact />
                </div>
                {/* End .contact Form */}
              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for Blog-2 */}

      {/* Start Modal for Blog-3 */}
      <Modal
        isOpen={isOpen3}
        onRequestClose={toggleModalThree}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalThree}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/blog/blog-3.jpg" alt="blog post"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>
                      <Trans>Blog Header 3</Trans>
                    </h2>
                    <div className="media">
                      <div className="avatar">
                        <img src="img/testimonial/team-3.jpg" alt="thumbnail" />
                      </div>
                      <div className="media-body">
                        <label><Trans>Blog Writer 3</Trans></label>
                        <span><Trans>Blog Date 3</Trans></span>
                      </div>
                    </div>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                    <Trans>Blog Description Long 3</Trans>
                  </div>
                  {/* End article content */}
                </article>
                {/* End Article */}

                <div className="contact-form article-comment">
                  <h4><Trans>Leave a Reply</Trans></h4>
                  <Contact />
                </div>
                {/* End .contact Form */}
              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for Blog-3 */}
    </>
  );
};

export default Blog;
