import React from "react";
import TextLoop from "react-text-loop";
import Social from "../Social";
import Fade from "react-reveal/Fade";
import { Trans } from 'react-i18next';

const Slider = () => {
  return (
    <>
      {/*  Home Banner */}
      <section
        id="home"
        className="home-banner overlay video-banner slider-three-animation"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "img/banner/bg-4.jpg"
          })`,
        }}
      >
        <div className="container">
          <div className="row align-items-center full-screen">
            <div className="col-lg-12">
              <div className="hb-typo text-left">
                <Fade bottom>
                  <h6><Trans>Hi There</Trans></h6>
                  <h1 className="font-alt"><Trans>Name</Trans></h1>
                  <h2>
                    <TextLoop>
                      <span className="loop-text"><Trans>Job Title 1</Trans></span>
                      <span className="loop-text"><Trans>Job Title 2</Trans></span>
                      <span className="loop-text"><Trans>Job Title 3</Trans></span>
                    </TextLoop>{" "}
                  </h2>
                </Fade>
              </div>
            </div>
          </div>
        </div>
        {/* End .container */}

        <div className="scroll-bottom go-to">
          <a href="#about"><Trans>Scroll</Trans></a>
        </div>

        {/* End bottom scroll */}

        <Social />
        {/* End social slide  */}
      </section>
      {/* End Home Banner  */}
    </>
  );
};

export default Slider;
