import React from "react";
import Fade from "react-reveal/Fade";
import { Trans } from 'react-i18next';

const Skills = () => {
  return (
    <>
      <div className="row">
        <div className="col-lg-4 my-3">
          <div className="sm-title">
            <h4 className="font-alt"><Trans>Education</Trans></h4>
          </div>

          <ul className="aducation-box theme-bg">
            <Fade bottom>
              <li>
                <span className="dark-bg"><Trans>Education Year 1</Trans></span>
                <h6><Trans>Education Title 1</Trans></h6>
                <p><Trans>Education Institute 1</Trans></p>
              </li>
            </Fade>
            <Fade bottom>
              <li>
              <span className="dark-bg"><Trans>Education Year 2</Trans></span>
                <h6><Trans>Education Title 2</Trans></h6>
                <p><Trans>Education Institute 2</Trans></p>
              </li>
            </Fade>
            <Fade bottom>
              <li>
              <span className="dark-bg"><Trans>Education Year 3</Trans></span>
                <h6><Trans>Education Title 3</Trans></h6>
                <p><Trans>Education Institute 3</Trans></p>
              </li>
            </Fade>
          </ul>

          {/* End  .aducation-box */}
        </div>
        {/* End .col */}

        <div className="col-lg-4 my-3">
          <div className="sm-title">
            <h4 className="font-alt"><Trans>Experience</Trans></h4>
          </div>
          <ul className="aducation-box dark-bg">
            <Fade bottom>
              <li>
                <span className="theme-bg"><Trans>Experience Year 1</Trans></span>
                <h6><Trans>Experience Title 1</Trans></h6>
                <p><Trans>Experience Institute 1</Trans></p>
              </li>
            </Fade>
            <Fade bottom>
              <li>
              <span className="theme-bg"><Trans>Experience Year 2</Trans></span>
                <h6><Trans>Experience Title 2</Trans></h6>
                <p><Trans>Experience Institute 2</Trans></p>
              </li>
            </Fade>
            <Fade bottom>
              <li>
              <span className="theme-bg"><Trans>Experience Year 3</Trans></span>
                <h6><Trans>Experience Title 3</Trans></h6>
                <p><Trans>Experience Institute 3</Trans></p>
              </li>
            </Fade>
          </ul>
          {/* End  .aducation-box */}
        </div>
        {/* End .col */}

        <div className="col-lg-4 ml-auto my-3">
          <div className="sm-title">
            <h4 className="font-alt"><Trans>My Skills</Trans></h4>
          </div>
          <div className="gray-bg skill-box">
            <div className="skill-lt">
              <Fade>
                <h6><Trans>Skill Title 1</Trans></h6>
              </Fade>
              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 92 + "%" }}
                >
                  <Fade left>
                    <span>92%</span>
                  </Fade>
                </div>
              </div>
            </div>
            {/* End skill-lt */}

            <div className="skill-lt">
              <Fade>
                <h6><Trans>Skill Title 2</Trans></h6>
              </Fade>
              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 75 + "%" }}
                >
                  <Fade left>
                    <span>75%</span>
                  </Fade>
                </div>
              </div>
            </div>
            {/* End skill-lt */}

            <div className="skill-lt">
              <Fade>
                <h6><Trans>Skill Title 3</Trans></h6>
              </Fade>
              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 86 + "%" }}
                >
                  <Fade left>
                    <span>86%</span>
                  </Fade>
                </div>
              </div>
            </div>
            {/* End skill-lt */}

            <div className="skill-lt">
              <Fade>
                <h6><Trans>Skill Title 4</Trans></h6>
              </Fade>
              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 88 + "%" }}
                >
                  <Fade left>
                    <span>88%</span>
                  </Fade>
                </div>
              </div>
            </div>
            {/* End skill-lt */}

            <div className="skill-lt">
              <Fade>
                <h6><Trans>Skill Title 5</Trans></h6>
              </Fade>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 92 + "%" }}
                >
                  <Fade left>
                    <span>92%</span>
                  </Fade>
                </div>
              </div>
            </div>
            {/* End skill-lt */}
          </div>
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default Skills;
