import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import Zoom from "react-reveal/Zoom";
import { Trans } from 'react-i18next';

const Portfolio = () => {
  return (
    <SimpleReactLightbox>
      <div className="positon-relative">
        <div className="portfolio-filter-01">
          <Tabs>
            <TabList className="filter d-flex justify-content-center">
              <Tab><Trans>Portfolio Tab 1</Trans></Tab>
              <Tab><Trans>Portfolio Tab 2</Trans></Tab>
              <Tab><Trans>Portfolio Tab 3</Trans></Tab>
              <Tab><Trans>Portfolio Tab 4</Trans></Tab>
              <Tab><Trans>Portfolio Tab 5</Trans></Tab>
            </TabList>
            {/* End tablist */}

            <SRLWrapper>
              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5><Trans>Portfolio Item Title 1</Trans></h5>
                          <span><Trans>Portfolio Item Description 1</Trans></span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/1.jpg" className="gallery-link">
                          <Zoom>
                            <img
                              src="img/portfolio/1.jpg"
                              alt="Motion Graphy"
                            />
                          </Zoom>
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}

                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5><Trans>Portfolio Item Title 2</Trans></h5>
                          <span><Trans>Portfolio Item Description 2</Trans></span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/3.jpg" className="gallery-link">
                          <Zoom>
                            <img
                              src="img/portfolio/3.jpg"
                              alt="Elearning App"
                            />
                          </Zoom>
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}

                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5><Trans>Portfolio Item Title 3</Trans></h5>
                          <span><Trans>Portfolio Item Description 3</Trans></span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/2.jpg" className="gallery-link">
                          <Zoom>
                            <img
                              src="img/portfolio/2.jpg"
                              alt="Visual Design"
                            />
                          </Zoom>
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}

                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5><Trans>Portfolio Item Title 4</Trans></h5>
                          <span><Trans>Portfolio Item Description 4</Trans></span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/4.jpg" className="gallery-link">
                          <Zoom>
                            <img
                              src="img/portfolio/4.jpg"
                              alt="Business Card"
                            />
                          </Zoom>
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}

                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5><Trans>Portfolio Item Title 5</Trans></h5>
                          <span><Trans>Portfolio Item Description 5</Trans></span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/5.jpg" className="gallery-link">
                          <Zoom>
                            <img
                              src="img/portfolio/5.jpg"
                              alt="Chatting Application"
                            />
                          </Zoom>
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}

                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5><Trans>Portfolio Item Title 6</Trans></h5>
                          <span><Trans>Portfolio Item Description 6</Trans></span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/6.jpg" className="gallery-link">
                          <Zoom>
                            <img
                              src="img/portfolio/6.jpg"
                              alt="Web Application"
                            />
                          </Zoom>
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}
                </div>
                {/* End list wrapper */}
              </TabPanel>
              {/* END ALL PORTFOLIO GALLERY */}

              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5><Trans>Portfolio Item Title 1</Trans></h5>
                          <span><Trans>Portfolio Item Description 1</Trans></span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/1.jpg" className="gallery-link">
                          <Zoom>
                            <img
                              src="img/portfolio/1.jpg"
                              alt="Motion Graphy"
                            />
                          </Zoom>
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}

                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5><Trans>Portfolio Item Title 2</Trans></h5>
                          <span><Trans>Portfolio Item Description 2</Trans></span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/2.jpg" className="gallery-link">
                          <Zoom>
                            <img
                              src="img/portfolio/2.jpg"
                              alt="Visual Design"
                            />
                          </Zoom>
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}

                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5><Trans>Portfolio Item Title 5</Trans></h5>
                          <span><Trans>Portfolio Item Description 5</Trans></span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/5.jpg" className="gallery-link">
                          <Zoom>
                            <img
                              src="img/portfolio/5.jpg"
                              alt="Chatting Application"
                            />
                          </Zoom>
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}
                </div>
                {/* End list wrapper */}
              </TabPanel>

              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5><Trans>Portfolio Item Title 2</Trans></h5>
                          <span><Trans>Portfolio Item Description 2</Trans></span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/2.jpg" className="gallery-link">
                          <Zoom>
                            <img
                              src="img/portfolio/2.jpg"
                              alt="Elearning App"
                            />
                          </Zoom>
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}

                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5><Trans>Portfolio Item Title 4</Trans></h5>
                          <span><Trans>Portfolio Item Description 4</Trans></span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/4.jpg" className="gallery-link">
                          <Zoom>
                            <img
                              src="img/portfolio/4.jpg"
                              alt="Business Card"
                            />
                          </Zoom>
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}

                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5><Trans>Portfolio Item Title 6</Trans></h5>
                          <span><Trans>Portfolio Item Description 6</Trans></span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/6.jpg" className="gallery-link">
                          <Zoom>
                            <img
                              src="img/portfolio/6.jpg"
                              alt="Web Application"
                            />
                          </Zoom>
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}
                </div>
                {/* End list wrapper */}
              </TabPanel>

              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5><Trans>Portfolio Item Title 1</Trans></h5>
                          <span><Trans>Portfolio Item Description 1</Trans></span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/1.jpg" className="gallery-link">
                          <Zoom>
                            <img
                              src="img/portfolio/1.jpg"
                              alt="Motion Graphy"
                            />
                          </Zoom>
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}
                </div>
                {/* End list wrapper */}
              </TabPanel>

              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5><Trans>Portfolio Item Title 1</Trans></h5>
                          <span><Trans>Portfolio Item Description 1</Trans></span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/1.jpg" className="gallery-link">
                          <Zoom>
                            <img
                              src="img/portfolio/1.jpg"
                              alt="Motion Graphy"
                            />
                          </Zoom>
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}

                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5><Trans>Portfolio Item Title 2</Trans></h5>
                          <span><Trans>Portfolio Item Description 2</Trans></span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/2.jpg" className="gallery-link">
                          <Zoom>
                            <img
                              src="img/portfolio/2.jpg"
                              alt="Elearning App"
                            />
                          </Zoom>
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}

                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5><Trans>Portfolio Item Title 3</Trans></h5>
                          <span><Trans>Portfolio Item Description 3</Trans></span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/3.jpg" className="gallery-link">
                          <Zoom>
                            <img
                              src="img/portfolio/3.jpg"
                              alt="Visual Design"
                            />
                          </Zoom>
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}

                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5><Trans>Portfolio Item Title 4</Trans></h5>
                          <span><Trans>Portfolio Item Description 4</Trans></span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/4.jpg" className="gallery-link">
                          <Zoom>
                            <img
                              src="img/portfolio/4.jpg"
                              alt="Business Card"
                            />
                          </Zoom>
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}

                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5><Trans>Portfolio Item Title 6</Trans></h5>
                          <span><Trans>Portfolio Item Description 6</Trans></span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/6.jpg" className="gallery-link">
                          <Zoom>
                            <img
                              src="img/portfolio/6.jpg"
                              alt="Web Application"
                            />
                          </Zoom>
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}
                </div>
                {/* End list wrapper */}
              </TabPanel>
            </SRLWrapper>
            {/* End tabpanel */}
          </Tabs>
        </div>
      </div>
    </SimpleReactLightbox>
  );
};

export default Portfolio;
