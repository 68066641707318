import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: {
          "My Services": "My Services",
          "My Services Description": `I design and develop services for customers of all sizes,
                                                specializing in creating stylish, modern websites, web
                                                services and online stores.`,
          "My Portfolio": "My Portfolio",
          "My Portfolio Description": `I design and develop services for customers of all sizes,
                                                specializing in creating stylish, modern websites, web
                                                services and online stores.`,
          "Recent Articles": "Recent Articles",
          "Recent Articles Description": `I design and develop services for customers of all sizes,
                                                    specializing in creating stylish, modern websites, web
                                                    services and online stores.`,
          "Contact Me": "Contact Me",
          "Get in Touch": "Get in Touch",
          "Get in Touch Description": `Always available for freelancing if the right project
                                                comes along, Feel free to contact me.`,
          "Get in Touch Address": `123 Stree New York City , <br />
                                United States Of America <br />
                                NY 750065.`,
          "Get in Touch Mail Addresses": `support@domain.com <br />
                                        info@domain.com `,

          "Get in Touch Phone Numbers": `+044 966 9696 636 <br />
                                    +044 966 9696 636 `,
          Home: "Home",
          "About Me": "About Me",
          Services: "Services",
          Portfolio: "Portfolio",
          Blog: "Blog",
          Contact: "Contact",
          "Hi There": "Hi There, I'm",
          Name: "Miladra Narzo",
          "Job Title 1": "Web Developer",
          "Job Title 2": "UI/UX Developer",
          "Job Title 3": "App Developer",
          Scroll: "Scroll",
          "Job Intro": `TR - A Lead App Developer based in Canada`,
          "Job Description": `I design and develop services for customers of all sizes,
                                        specializing in creating stylish, modern websites, web services
                                        and online stores. My passion is to design digital user
                                        experiences through the bold interface and meaningful
                                        interactions. Check out my Portfolio`,
          Birthday: "Birthday",
          "Birthday Value": "4th April 1991",
          Age: "Age",
          "Age Value": "22 Yr",
          Residence: "Residence",
          "Residence Value": "Canada",
          Address: "Address",
          "Address Value": "California, USA",
          Email: "Email",
          "Email Value": "info@domain.com",
          Phone: "Phone",
          "Phone Value": "820-885-3321",
          "Skype Value": "skype.0404",
          Freelance: "Freelance",
          "Freelance value": "Available",
          "Download CV": "Download CV",
          "Service Title 1": "Development",
          "Service Title 2": "Graphic",
          "Service Title 3": "Web Design",
          "Service Title 4": "Web Design",
          "Service Title 5": "Photography",
          "Service Title 6": "Mobile Apps",
          "Service Description 1": `1 - Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          "Service Description 2": `2 - Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          "Service Description 3": `3 - Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          "Service Description 4": `4 - Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          "Service Description 5": `5 - Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          "Service Description 6": `6 - Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          "Education Title 1": "Front-end Engineer",
          "Education Year 1": "2010-2012",
          "Education Institute 1": "International Design Institute",
          "Education Title 2": "Graphic Designer",
          "Education Year 2": "2010-2012",
          "Education Institute 2": "Rio Design Institute",
          "Education Title 3": "Visual Designer",
          "Education Year 3": "2010-2012",
          "Education Institute 3": "Jakarta Design Institute",

          "Experience Title 1": "Software Engineer",
          "Experience Year 1": "2010-2012",
          "Experience Institute 1": "Furious Design Institute",
          "Experience Title 2": "Android Developer",
          "Experience Year 2": "2010-2012",
          "Experience Institute 2": "Ketlimte Design Ltd.",
          "Experience Title 3": "Rubby Developer",
          "Experience Year 3": "2010-2012",
          "Experience Institute 3": "Randara Institute Academy",

          "Skill Title 1": "React js",
          "Skill Title 2": "WordPress",
          "Skill Title 3": "Vue js",
          "Skill Title 4": "UI/UX",
          "Skill Title 5": "CSS3",

          Education: "Education",
          Experience: "Experience",
          "My Skills": "My Skills",

          "Portfolio Tab 1": "All",
          "Portfolio Tab 2": "Branding",
          "Portfolio Tab 3": "Photography",
          "Portfolio Tab 4": "Fashion",
          "Portfolio Tab 5": "Product",

          "Portfolio Item Title 1": "Motion Graphy",
          "Portfolio Item Title 2": "Elearning App",
          "Portfolio Item Title 3": "Visual Design",
          "Portfolio Item Title 4": "Business Card",
          "Portfolio Item Title 5": "Chatting Application",
          "Portfolio Item Title 6": "Web Application",

          "Portfolio Item Description 1": "Android App",
          "Portfolio Item Description 2": "Nuna ios App",
          "Portfolio Item Description 3": "Themeforest Market",
          "Portfolio Item Description 4": "Graphicriver Market",
          "Portfolio Item Description 5": "Codecanyon Market",
          "Portfolio Item Description 6": "Behance Shot",

          "Review Description 1": `  I design and develop services for customers of all sizes,
                                  specializing in creating stylish, modern websites, web services
                                  and online stores.`,
          "Review Description 2": `  I design and develop services for customers of all sizes,
                                  specializing in creating stylish, modern websites, web services
                                  and online stores.`,
          "Review Description 3": `  I design and develop services for customers of all sizes,
                                  specializing in creating stylish, modern websites, web services
                                  and online stores.`,

          "Review Rating 1": "5.0",
          "Review Rating 2": "4.8",
          "Review Rating 3": "5.0",

          "Reviewer Name 1": "Nancy Byers",
          "Reviewer Name 2": "Jara Afsari",
          "Reviewer Name 3": "Janiaya kiaram",

          "Blog Header 1": `I saw few die of hunger of eating, a hundred thousand.`,
          "Blog Description Short 1": `Integer vitae mi libero. Aliquam consequat dolor libero, nec
                                      varius elit tempusporttitor.`,
          "Blog Description Long 1": `<p>
                                        Aenean eleifend ante maecenas pulvinar montes lorem et
                                        pede dis dolor pretium donec dictum. Vici consequat justo
                                        enim. Venenatis eget adipiscing luctus lorem. Adipiscing
                                        veni amet luctus enim sem libero tellus viverra venenatis
                                        aliquam. Commodo natoque quam pulvinar elit.
                                      </p>
                                      <p>
                                        Eget aenean tellus venenatis. Donec odio tempus. Felis
                                        arcu pretium metus nullam quam aenean sociis quis sem
                                        neque vici libero. Venenatis nullam fringilla pretium
                                        magnis aliquam nunc vulputate integer augue ultricies
                                        cras. Eget viverra feugiat cras ut. Sit natoque montes
                                        tempus ligula eget vitae pede rhoncus maecenas
                                        consectetuer commodo condimentum aenean.
                                      </p>
                                      <h4>What are my payment options?</h4>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                        sed do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                      </p>
                                      <blockquote>
                                        <p>
                                          Lorem ipsum dolor sit amet, consectetur adipisicing
                                          elit, sed do eiusmod tempor incididunt ut labore et
                                          dolore magna aliqua. Ut enim ad minim veniam.
                                        </p>
                                        <p className="blockquote-footer">
                                          Someone famous in{" "}
                                          <cite title="Source Title">Dick Grayson</cite>
                                        </p>
                                      </blockquote>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                        sed do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit
                                        esse cillum dolore eu fugiat nulla pariatur. Excepteur
                                        sint occaecat cupidatat non proident, sunt in culpa qui
                                        officia deserunt mollit anim id est laborum.
                                      </p>`,
          "Blog Writer 1": "BY ALEX",
          "Blog Date 1": "26 FEB 2020",

          "Blog Header 2": `New Freehand Templates, built for the whole team.`,
          "Blog Description Short 2": `Consectetur dorem ipsum dolor sit amet, adipisicing elit, sed
                                      do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          "Blog Description Long 2": `<p>
                                        Aenean eleifend ante maecenas pulvinar montes lorem et
                                        pede dis dolor pretium donec dictum. Vici consequat justo
                                        enim. Venenatis eget adipiscing luctus lorem. Adipiscing
                                        veni amet luctus enim sem libero tellus viverra venenatis
                                        aliquam. Commodo natoque quam pulvinar elit.
                                      </p>
                                      <p>
                                        Eget aenean tellus venenatis. Donec odio tempus. Felis
                                        arcu pretium metus nullam quam aenean sociis quis sem
                                        neque vici libero. Venenatis nullam fringilla pretium
                                        magnis aliquam nunc vulputate integer augue ultricies
                                        cras. Eget viverra feugiat cras ut. Sit natoque montes
                                        tempus ligula eget vitae pede rhoncus maecenas
                                        consectetuer commodo condimentum aenean.
                                      </p>
                                      <h4>What are my payment options?</h4>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                        sed do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                      </p>
                                      <blockquote>
                                        <p>
                                          Lorem ipsum dolor sit amet, consectetur adipisicing
                                          elit, sed do eiusmod tempor incididunt ut labore et
                                          dolore magna aliqua. Ut enim ad minim veniam.
                                        </p>
                                        <p className="blockquote-footer">
                                          Someone famous in{" "}
                                          <cite title="Source Title">Dick Grayson</cite>
                                        </p>
                                      </blockquote>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                        sed do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit
                                        esse cillum dolore eu fugiat nulla pariatur. Excepteur
                                        sint occaecat cupidatat non proident, sunt in culpa qui
                                        officia deserunt mollit anim id est laborum.
                                      </p>`,
          "Blog Writer 2": "BY SMITH",
          "Blog Date 2": "05 JAN 2020",

          "Blog Header 3": `To the lover of wilderness, Alaska is one of the most.`,
          "Blog Description Short 3": `Estibulum eu ante sed dui porta porttitor. Nullam a placerat
                                      velit. Suspendisse non ligula felis`,
          "Blog Description Long 3": `<p>
                                        Aenean eleifend ante maecenas pulvinar montes lorem et
                                        pede dis dolor pretium donec dictum. Vici consequat justo
                                        enim. Venenatis eget adipiscing luctus lorem. Adipiscing
                                        veni amet luctus enim sem libero tellus viverra venenatis
                                        aliquam. Commodo natoque quam pulvinar elit.
                                      </p>
                                      <p>
                                        Eget aenean tellus venenatis. Donec odio tempus. Felis
                                        arcu pretium metus nullam quam aenean sociis quis sem
                                        neque vici libero. Venenatis nullam fringilla pretium
                                        magnis aliquam nunc vulputate integer augue ultricies
                                        cras. Eget viverra feugiat cras ut. Sit natoque montes
                                        tempus ligula eget vitae pede rhoncus maecenas
                                        consectetuer commodo condimentum aenean.
                                      </p>
                                      <h4>What are my payment options?</h4>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                        sed do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                      </p>
                                      <blockquote>
                                        <p>
                                          Lorem ipsum dolor sit amet, consectetur adipisicing
                                          elit, sed do eiusmod tempor incididunt ut labore et
                                          dolore magna aliqua. Ut enim ad minim veniam.
                                        </p>
                                        <p className="blockquote-footer">
                                          Someone famous in{" "}
                                          <cite title="Source Title">Dick Grayson</cite>
                                        </p>
                                      </blockquote>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                        sed do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit
                                        esse cillum dolore eu fugiat nulla pariatur. Excepteur
                                        sint occaecat cupidatat non proident, sunt in culpa qui
                                        officia deserunt mollit anim id est laborum.
                                      </p>`,
          "Blog Writer 3": "BY RIO",
          "Blog Date 3": "12 AUG 2020",

          "Read More": "Read More",
          "Leave a Reply": "Leave a Reply",
          "Full Name": "Full Name",
          "Name is required": "Name is required.",
          "Email is Required": "Email is Required.",
          "Entered value does not match email format": "Entered value does not match email format.",
          "Subject": "Subject",
          "Subject is required": "Subject is required.",
          "Comment": "Comment",
          "Comment is required": "Comment is required.",
          "Send your message": "Send your message",


        },
      },
      tr: {
        translations: {
          "My Services": "TR - My Services",
          "My Services Description": `TR - I design and develop services for customers of all sizes,
                                                specializing in creating stylish, modern websites, web
                                                services and online stores.`,
          "My Portfolio": "TR - My Portfolio",
          "My Portfolio Description": `TR - I design and develop services for customers of all sizes,
                                                specializing in creating stylish, modern websites, web
                                                services and online stores.`,
          "Recent Articles": "TR - Recent Articles",
          "Recent Articles Description": `TR - I design and develop services for customers of all sizes,
                                                    specializing in creating stylish, modern websites, web
                                                    services and online stores.`,
          "Contact Me": "TR - Contact Me",
          "Get in Touch": "TR - Get in Touch",
          "Get in Touch Description": `TR - Always available for freelancing if the right project
                                                comes along, Feel free to contact me.`,
          "Get in Touch Address": `TR - 123 Stree New York City , <br />
                                United States Of America  <br />
                                NY 750065.`,
          "Get in Touch Mail Addresses": `TR - support@domain.com <br />
                                        info@domain.com `,

          "Get in Touch Phone Numbers": `TR - +044 966 9696 636 <br />
                                        +044 966 9696 636`,
          Home: "TR - Home",
          "About Me": "TR - About Me",
          Services: "TR - Services",
          Portfolio: "TR - Portfolio",
          Blog: "TR - Blog",
          Contact: "TR - Contact",
          "Hi There": "TR - Hi There, I'm",
          Name: "TR - Miladra Narzo",
          "Job Title 1": "TR - Web Developer",
          "Job Title 2": "TR - UI/UX Developer",
          "Job Title 3": "TR - App Developer",
          Scroll: "TR - Scroll",
          "Job Intro": `TR - A Lead App Developer based in Canada`,
          "Job Description": `TR - I design and develop services for customers of all sizes,
                                        specializing in creating stylish, modern websites, web services
                                        and online stores. My passion is to design digital user
                                        experiences through the bold interface and meaningful
                                        interactions. Check out my Portfolio`,
          Birthday: "TR - Birthday",
          "Birthday Value": "TR - 4th April 1991",
          Age: "TR - Age",
          "Age Value": "TR - 22 Yr",
          Residence: "TR - Residence",
          "Residence Value": "TR - Canada",
          Address: "TR - Address",
          "Address Value": "TR - California, USA",
          Email: "TR - Email",
          "Email Value": "TR - info@domain.com",
          Phone: "TR - Phone",
          "Phone Value": "TR - 820-885-3321",
          "Skype Value": "TR - skype.0404",
          Freelance: "TR - Freelance",
          "Freelance Value": "TR - Available",
          "Download CV": "TR - Download CV",
          "Service Title 1": "TR - Development",
          "Service Title 2": "TR - Graphic",
          "Service Title 3": "TR - Web Design",
          "Service Title 4": "TR - Web Design",
          "Service Title 5": "TR - Photography",
          "Service Title 6": "TR - Mobile Apps",
          "Service Description 1": `TR - 1 - Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          "Service Description 2": `TR - 2 - Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          "Service Description 3": `TR - 3 - Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          "Service Description 4": `TR - 4 - Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          "Service Description 5": `TR - 5 - Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          "Service Description 6": `TR - 6 - Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua.`,

          "Education Title 1": "TR - Front-end Engineer",
          "Education Year 1": "2010-2012",
          "Education Institute 1": "TR - International Design Institute",
          "Education Title 2": "TR - Graphic Designer",
          "Education Year 2": "2010-2012",
          "Education Institute 2": "TR - Rio Design Institute",
          "Education Title 3": "TR - Visual Designer",
          "Education Year 3": "2010-2012",
          "Education Institute 3": "TR - Jakarta Design Institute",

          "Experience Title 1": "TR - Software Engineer",
          "Experience Year 1": "2010-2012",
          "Experience Institute 1": "TR - Furious Design Institute",
          "Experience Title 2": "TR - Android Developer",
          "Experience Year 2": "2010-2012",
          "Experience Institute 2": "TR - Ketlimte Design Ltd.",
          "Experience Title 3": "TR - Rubby Developer",
          "Experience Year 3": "2010-2012",
          "Experience Institute 3": "TR - Randara Institute Academy",

          "Skill Title 1": "TR - React js",
          "Skill Title 2": "TR - WordPress",
          "Skill Title 3": "TR - Vue js",
          "Skill Title 4": "TR - UI/UX",
          "Skill Title 5": "TR - CSS3",

          Education: "TR - Education",
          Experience: "TR - Experience",
          "My Skills": "TR - My Skills",

          "Portfolio Tab 1": "TR - All",
          "Portfolio Tab 2": "TR - Branding",
          "Portfolio Tab 3": "TR - Photography",
          "Portfolio Tab 4": "TR - Fashion",
          "Portfolio Tab 5": "TR - Product",

          "Portfolio Item Title 1": "TR - Motion Graphy",
          "Portfolio Item Title 2": "TR - Elearning App",
          "Portfolio Item Title 3": "TR - Visual Design",
          "Portfolio Item Title 4": "TR - Business Card",
          "Portfolio Item Title 5": "TR - Chatting Application",
          "Portfolio Item Title 6": "TR - Web Application",

          "Portfolio Item Description 1": "TR - Android App",
          "Portfolio Item Description 2": "TR - Nuna ios App",
          "Portfolio Item Description 3": "TR - Themeforest Market",
          "Portfolio Item Description 4": "TR - Graphicriver Market",
          "Portfolio Item Description 5": "TR - Codecanyon Market",
          "Portfolio Item Description 6": "TR - Behance Shot",

          "Review Description 1": `TR -  I design and develop services for customers of all sizes,
                    specializing in creating stylish, modern websites, web services
                    and online stores.`,
          "Review Description 2": `TR -  I design and develop services for customers of all sizes,
                    specializing in creating stylish, modern websites, web services
                    and online stores.`,
          "Review Description 3": `TR -  I design and develop services for customers of all sizes,
                    specializing in creating stylish, modern websites, web services
                    and online stores.`,

          "Review Rating 1": "TR - 5.0",
          "Review Rating 2": "TR - 4.8",
          "Review Rating 3": "TR - 5.0",

          "Reviewer Name 1": "TR - Nancy Byers",
          "Reviewer Name 2": "TR - Jara Afsari",
          "Reviewer Name 3": "TR - Janiaya kiaram",

          "Blog Header 1": `TR - I saw few die of hunger of eating, a hundred thousand.`,
          "Blog Description Short 1": `TR - Integer vitae mi libero. Aliquam consequat dolor libero, nec
                                      varius elit tempusporttitor.`,
          "Blog Description Long 1": `TR
                                      <p>
                                        Aenean eleifend ante maecenas pulvinar montes lorem et
                                        pede dis dolor pretium donec dictum. Vici consequat justo
                                        enim. Venenatis eget adipiscing luctus lorem. Adipiscing
                                        veni amet luctus enim sem libero tellus viverra venenatis
                                        aliquam. Commodo natoque quam pulvinar elit.
                                      </p>
                                      <p>
                                        Eget aenean tellus venenatis. Donec odio tempus. Felis
                                        arcu pretium metus nullam quam aenean sociis quis sem
                                        neque vici libero. Venenatis nullam fringilla pretium
                                        magnis aliquam nunc vulputate integer augue ultricies
                                        cras. Eget viverra feugiat cras ut. Sit natoque montes
                                        tempus ligula eget vitae pede rhoncus maecenas
                                        consectetuer commodo condimentum aenean.
                                      </p>
                                      <h4>What are my payment options?</h4>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                        sed do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                      </p>
                                      <blockquote>
                                        <p>
                                          Lorem ipsum dolor sit amet, consectetur adipisicing
                                          elit, sed do eiusmod tempor incididunt ut labore et
                                          dolore magna aliqua. Ut enim ad minim veniam.
                                        </p>
                                        <p className="blockquote-footer">
                                          Someone famous in{" "}
                                          <cite title="Source Title">Dick Grayson</cite>
                                        </p>
                                      </blockquote>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                        sed do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit
                                        esse cillum dolore eu fugiat nulla pariatur. Excepteur
                                        sint occaecat cupidatat non proident, sunt in culpa qui
                                        officia deserunt mollit anim id est laborum.
                                      </p>`,
          "Blog Writer 1": "TR - BY ALEX",
          "Blog Date 1": "TR - 26 FEB 2020",

          "Blog Header 2": `TR - New Freehand Templates, built for the whole team.`,
          "Blog Description Short 2": `TR - Consectetur dorem ipsum dolor sit amet, adipisicing elit, sed
                                      do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          "Blog Description Long 2": `TR 
                                      <p>
                                        Aenean eleifend ante maecenas pulvinar montes lorem et
                                        pede dis dolor pretium donec dictum. Vici consequat justo
                                        enim. Venenatis eget adipiscing luctus lorem. Adipiscing
                                        veni amet luctus enim sem libero tellus viverra venenatis
                                        aliquam. Commodo natoque quam pulvinar elit.
                                      </p>
                                      <p>
                                        Eget aenean tellus venenatis. Donec odio tempus. Felis
                                        arcu pretium metus nullam quam aenean sociis quis sem
                                        neque vici libero. Venenatis nullam fringilla pretium
                                        magnis aliquam nunc vulputate integer augue ultricies
                                        cras. Eget viverra feugiat cras ut. Sit natoque montes
                                        tempus ligula eget vitae pede rhoncus maecenas
                                        consectetuer commodo condimentum aenean.
                                      </p>
                                      <h4>What are my payment options?</h4>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                        sed do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                      </p>
                                      <blockquote>
                                        <p>
                                          Lorem ipsum dolor sit amet, consectetur adipisicing
                                          elit, sed do eiusmod tempor incididunt ut labore et
                                          dolore magna aliqua. Ut enim ad minim veniam.
                                        </p>
                                        <p className="blockquote-footer">
                                          Someone famous in{" "}
                                          <cite title="Source Title">Dick Grayson</cite>
                                        </p>
                                      </blockquote>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                        sed do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit
                                        esse cillum dolore eu fugiat nulla pariatur. Excepteur
                                        sint occaecat cupidatat non proident, sunt in culpa qui
                                        officia deserunt mollit anim id est laborum.
                                      </p>`,
          "Blog Writer 2": "TR - BY SMITH",
          "Blog Date 2": "TR - 05 JAN 2020",

          "Blog Header 3": `TR - To the lover of wilderness, Alaska is one of the most.`,
          "Blog Description Short 3": `Estibulum eu ante sed dui porta porttitor. Nullam a placerat
                                      velit. Suspendisse non ligula felis`,
          "Blog Description Long 3": `TR
                                      <p>
                                        Aenean eleifend ante maecenas pulvinar montes lorem et
                                        pede dis dolor pretium donec dictum. Vici consequat justo
                                        enim. Venenatis eget adipiscing luctus lorem. Adipiscing
                                        veni amet luctus enim sem libero tellus viverra venenatis
                                        aliquam. Commodo natoque quam pulvinar elit.
                                      </p>
                                      <p>
                                        Eget aenean tellus venenatis. Donec odio tempus. Felis
                                        arcu pretium metus nullam quam aenean sociis quis sem
                                        neque vici libero. Venenatis nullam fringilla pretium
                                        magnis aliquam nunc vulputate integer augue ultricies
                                        cras. Eget viverra feugiat cras ut. Sit natoque montes
                                        tempus ligula eget vitae pede rhoncus maecenas
                                        consectetuer commodo condimentum aenean.
                                      </p>
                                      <h4>What are my payment options?</h4>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                        sed do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                      </p>
                                      <blockquote>
                                        <p>
                                          Lorem ipsum dolor sit amet, consectetur adipisicing
                                          elit, sed do eiusmod tempor incididunt ut labore et
                                          dolore magna aliqua. Ut enim ad minim veniam.
                                        </p>
                                        <p className="blockquote-footer">
                                          Someone famous in{" "}
                                          <cite title="Source Title">Dick Grayson</cite>
                                        </p>
                                      </blockquote>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                        sed do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit
                                        esse cillum dolore eu fugiat nulla pariatur. Excepteur
                                        sint occaecat cupidatat non proident, sunt in culpa qui
                                        officia deserunt mollit anim id est laborum.
                                      </p>`,
          "Blog Writer 3": "TR - BY RIO",
          "Blog Date 3": "TR - 12 AUG 2020",

          "Read More": "TR - Read More",
          "Leave a Reply": "TR - Leave a Reply",
          "Full Name": "TR - Full Name",
          "Name is required": "TR - Name is required.",
          "Email is Required": "TR - Email is Required.",
          "Entered value does not match email format": "TR - Entered value does not match email format.",
          "Subject": "TR - Subject",
          "Subject is required": "TR - Subject is required.",
          "Comment": "TR - Comment",
          "Comment is required": "TR - Comment is required.",
          "Send your message": "TR - Send your message",

        },
      },
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
