import React from "react";
import Fade from "react-reveal/Fade";
import { Trans } from 'react-i18next';

const About = () => {
  return (
    <>
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-6 my-3">
          <div className="img-box">
            <Fade left>
              <img src="img/about/about-4.jpg" alt="smiling a girl" />
            </Fade>
          </div>
        </div>

        <div className="col-lg-6 my-3">
          <div className="typo-box about-me">
            <Fade bottom>
              <h3><Trans>Name</Trans></h3>
              <h5>
                <span className="color-theme">
                  <Trans>Job Intro</Trans>
                </span>
              </h5>
              <p>
                <Trans>Job Description</Trans>
              </p>
              <div className="row about-list">
                <div className="col-md-6">
                  <div className="media">
                    <label><Trans>Birthday</Trans></label>
                    <p><Trans>Birthday Value</Trans></p>
                  </div>
                  <div className="media">
                    <label><Trans>Age</Trans></label>
                    <p><Trans>Age Value</Trans></p>
                  </div>
                  <div className="media">
                    <label><Trans>Residence</Trans></label>
                    <p><Trans>Residence Value</Trans></p>
                  </div>
                  <div className="media">
                    <label><Trans>Address</Trans></label>
                    <p><Trans>Address Value</Trans></p>
                  </div>
                </div>
                {/* End .col */}

                <div className="col-md-6">
                  <div className="media">
                    <label><Trans>Email</Trans></label>
                    <p><Trans>Email Value</Trans></p>
                  </div>
                  <div className="media">
                    <label><Trans>Phone</Trans></label>
                    <p><Trans>Phone Value</Trans></p>
                  </div>
                  <div className="media">
                    <label>Skype</label>
                    <p><Trans>Skype Value</Trans></p>
                  </div>
                  <div className="media">
                    <label><Trans>Freelance</Trans></label>
                    <p><Trans>Freelance Value</Trans></p>
                  </div>
                </div>
                {/* End .col */}
              </div>
              {/* End .row */}
              <div className="btn-bar">
                <a
                  className="px-btn px-btn-theme"
                  href="img/resume.png"
                  download
                >
                  <Trans>Download CV</Trans>
                </a>
              </div>
            </Fade>
          </div>
          {/* End .about me */}
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default About;
