import React from "react";
import { useForm } from "react-hook-form";
import { Trans } from 'react-i18next';

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, e) => {
    e.target.reset();
    console("Message submited: " + JSON.stringify(data));
  };

  return (
    <>
      <form className="contct-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder= {<Trans>Full Name</Trans>}
                {...register("name", { required: true })}
              />
              <label className="form-label"><Trans>Full Name</Trans></label>
              {errors.name && errors.name.type === "required" && (
                <span className="invalid-feedback"><Trans>Name is required</Trans></span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-md-6">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder={<Trans>Email</Trans>}
                {...register(
                  "email",
                  {
                    required: <Trans>Email is Required</Trans>,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: <Trans>Entered value does not match email format</Trans>,
                    },
                  },
                  { required: true }
                )}
              />
              <label className="form-label"><Trans>Email</Trans></label>
              {errors.email && (
                <span className="invalid-feedback">{errors.email.message}</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder={<Trans>Subject</Trans>}
                {...register("subject", { required: true })}
              />
              <label className="form-label"><Trans>Subject</Trans></label>
              {errors.subject && (
                <span className="invalid-feedback"><Trans>Subject is required.</Trans></span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <div className="form-group">
              <textarea
                rows="4"
                className="form-control"
                placeholder={<Trans>Comment</Trans>}
                {...register("comment", { required: true })}
              ></textarea>
              <label className="form-label"><Trans>Comment</Trans></label>
              {errors.comment && (
                <span className="invalid-feedback"><Trans>Comment is required.</Trans></span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <div className="btn-bar">
              <button className="px-btn px-btn-theme"><Trans>Send your message</Trans></button>
            </div>
          </div>
          {/* End .col-12 */}
        </div>
      </form>
    </>
  );
};

export default Contact;
